import VueRouter from "vue-router";
import store from "./store";
const routes = [
  { path: "/", name: "/", component: () => import("@/views/SellPage.vue") },
  {
    name: "login",
    path: "/login",
    component: () => import("@/views/LoginPage.vue"),
  },
  {
    name: "register",
    path: "/register",
    component: () => import("@/views/RegisterPage.vue"),
  },
  {
    path: "/sell",
    name: "sell",
    component: () => import("@/views/SellPage.vue"),
  },
  {
    path: "/sellanimal",
    name: "sellanimal",
    component: () => import("@/views/SellAnimalPage.vue"),
  },
  {
    path: "/sellzodiac",
    name: "sellzodiac",
    component: () => import("@/views/SellZodiacPage.vue"),
  },
  {
    path: "/sellhistory",
    name: "sellhistory",
    component: () => import("@/views/SellHistoryPage.vue"),
  },
  {
    path: "/winbill",
    name: "winbill",
    component: () => import("@/views/WinBillPage.vue"),
  },
  {
    path: "/changepassword",
    name: "changepassword",
    component: () => import("@/views/ChangePasswordPage.vue"),
  },
  {
    path: "/cancel",
    name: "cancel",
    component: () => import("@/views/CancelPage.vue"),
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("@/views/LogoutPage.vue"),
  },
  {
    path: "/settingurl",
    name: "settingurl",
    component: () => import("@/views/SettingUrlPage.vue"),
  },
  {
    path: "/drawhistory",
    name: "drawhistory",
    component: () => import("@/views/DrawHistoryPage.vue"),
  },
  {
    path: "/update",
    name: "update",
    component: () => import("@/views/UpdatePage.vue"),
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (!["/login", "/register"].includes(to.path)) {
    const user = store.get("user");
    if (!user || !user["accessToken"]) {
      router.replace({ path: "/login" });
    }
  }
  next();
});

export default router;
