import Axios from "axios";
import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App.vue";
import "./assets/css/tailwind.css";
import mixin from "./mixin";
import router from "./router";
import store from "./store";
import axios from "axios";

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.mixin(mixin);

Axios.defaults.baseURL = process.env.VUE_APP_API_URL;
const SIGNATURE = process.env.VUE_APP_SIGNATURE;
const TOKENEXPIRESINMINUTE = process.env.VUE_APP_TOKENEXPIRESINMINUTE ?? 300;
// Axios.defaults.timeout = 60000;
Axios.interceptors.request.use(
  (config) => {
    config.timeout = 10000;
    config.timeoutErrorMessage = "Time Out";
    const user = store.get("user");
    if (user) {
      if (!config.headers) {
        config.headers = [];
      }
      if (!config.headers["Authorization"]) {
        if (user["accessToken"]) {
          config.headers[`Authorization`] = `${user["accessToken"]}`;
        }
      }
      if (!config.headers["Content-type"]) {
        config.headers["Content-type"] = "application/json";
      }
    }

    try {
      let data = config.url;
      if (config.data) {
        data = JSON.stringify(config.data);
      }
      const signature = SIGNATURE ?? window["Android"].signData(data);
      config.headers[`Signature`] = `${signature}`;
    } catch (e) {
      console.error("SIGNDATA", e);
    }

    try {
      const deviceid =
        process.env.VUE_APP_DEVICEID ??
        window["Android"].getFromPreferences("LOTLINK-POS-DEVICEID");
      if (deviceid) {
        config.headers[`X-DeviceId`] = `${deviceid}`;
      } else {
        if (
          config.url.includes(`signin`) ||
          config.url.includes(`sellTicket`)
        ) {
          return Promise.reject({
            response: {
              data: {
                message: "ແອັບມີບັນຫາ ກະລຸນາລຶບແອັບ ແລ້ວຕິດຕັ້ງໃໝ່!!!",
              },
            },
          });
        }
      }
    } catch (error) {
      console.error("GETFROMPREFERENCES", error);
    }

    return config;
  },
  (error) => {
    return Promise.reject("request", error);
  }
);

Axios.interceptors.response.use(
  (response) => {
    const lastrequesttime = store.get("lastrequesttime");
    if (lastrequesttime) {
      try {
        if (Date.now() - lastrequesttime > TOKENEXPIRESINMINUTE * 60 * 1000) {
          const user = store.get("user");
          if (user && user["refreshToken"]) {
            const body = {
              refreshToken: user["refreshToken"],
            };
            axios
              .post("/refreshToken", body)
              .then((res) => {
                if (res["accessToken"]) {
                  user["accessToken"] = res["accessToken"];
                  store.set("user", user);
                }
              })
              .catch((e) => {
                console.error(e);
              });
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
    store.set("lastrequesttime", Date.now());
    if (response.status == 200) {
      return Promise.resolve(response.data);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    console.log("error", error);
    if (
      error.message.includes("403") ||
      error.message.includes("Invalid token")
    ) {
      store.set("user", null);
      if (window["Android"]) {
        window["Android"].showToast("ໝົດເວລາ, ກະລຸນາເຂົ້າສູ່ລະບົບ");
      }
      router.replace({ path: "/login" });
    }
    return Promise.reject(error);
  }
);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
