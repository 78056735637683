<template>
  <div id="app" class="h-full overflow-y-hidden">
    <router-view />
    <div v-if="toastmessage" class="toast">
      {{ toastmessage }}
    </div>
  </div>
</template>

<script>
import { get } from "vuex-pathify";

export default {
  name: "App",
  computed: {
    user: get("user"),
    toastmessage: get("toastmessage"),
  },
  data() {
    return {};
  },
  mounted() {},
  destroyed() {},
  beforeDestroy() {},
  methods: {
    onBackPress() {
      this.toast("ກັບຄືນ");
    },
  },
};
</script>

<style>
@font-face {
  font-family: "NotoLao";
  src: local("NotoSansLaoLooped"),
    url("@/assets/fonts/NotoSansLaoLooped-VariableFont_wdth,wght.ttf")
      format("truetype");
}
#app {
  font-family: NotoLao;
}
.bg-white {
  background-color: #fff;
}
.bg-red-600 {
  background-color: #dc2626;
}
.bg-blue-600 {
  background-color: #2563eb;
}
.bg-green-600 {
  background-color: #059669;
}
.bg-yellow-600 {
  background-color: #d97706;
}
.bg-orange-600 {
  background-color: #ea580c;
}
.bg-indigo-600 {
  background-color: #4f46e5;
}
.bg-purple-600 {
  background-color: #9333ea;
}
.bg-pink-600 {
  background-color: #d946ef;
}
.bg-gray-100 {
  background-color: #f3f4f6;
}
.bg-gray-200 {
  background-color: #e5e7eb;
}
.bg-gray-300 {
  background-color: #d1d5db;
}
.bg-gray-400 {
  background-color: #9ca3af;
}
.bg-gray-600 {
  background-color: #4b5563;
}
.bg-gray-700 {
  background-color: #374151;
}
.bg-black {
  background-color: #000;
}
.border-blue-600 {
  border-color: #2563eb;
}
.border-gray-400 {
  border: 1px solid #cbd5e0;
}
.flex {
  display: flex;
}
.text-white {
  color: #fff;
}
.text-blue-600 {
  color: #2563eb;
}
.text-red-600 {
  color: #dc2626;
}
.text-green-600 {
  color: #059669;
}
.toast {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  opacity: 0.9;
}
.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease;
}
.slide-enter-from,
.slide-leave-to {
  transform: translateX(-100%);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
